import * as React from "react"
import styled from "styled-components"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Sidebar } from "../components/sidebar"
import { StyledArticle, StyledPage, StyledWrapper } from "../components/wrapper"
import { PostHead } from "../components/postHead"

const StyledForm = styled.form`
  display: grid;
  row-gap: 30px;
  padding: 0 10px;

  p {
    margin: 0;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: solid 1px #dddddd;
    font-size: var(--f5);
    box-sizing: border-box;
    font-family: inherit;
    font-weight: normal;
    outline: none;
    color: currentColor;
    appearance: none;
    border-radius: 0;
    resize: none;
  }

  button {
    justify-self: start;
    padding: 15px 30px;
    border: solid 1px currentColor;
    font-family: inherit;
    font-size: var(--f5);
    background-color: var(--blue);
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #686868;
    }
  }

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`

const ContactPage = ({ location }) => (
  <Layout>
    <Seo pagetitle="お問い合わせ" pagepath={location.pathname} />
    <StyledWrapper>
      <div>
        <StyledPage>
          <StyledArticle>
            <PostHead title={`お問い合わせ`} />
            <StyledForm name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />

              <p>
                <label>
                  お名前（必須）
                  <br />
                  <input type="text" name="name" required />
                </label>
              </p>
              <p>
                <label>
                  メールアドレス（必須）
                  <br />
                  <input type="text" name="email" required />
                </label>
              </p>
              <p>
                <label>
                  お問い合わせ内容
                  <br />
                  <textarea name="content" rows="10" required></textarea>
                </label>
              </p>
              <button type="submit">送信する</button>
            </StyledForm>
          </StyledArticle>
        </StyledPage>
        <Sidebar />
      </div>
    </StyledWrapper>
  </Layout>
)

export default ContactPage
